<template>
  <!-- 新增章节弹窗 -->
  <a-modal :visible="isShow" :title="title" @ok="handleOk" @cancel="handleCancel">
    <UpdateImg @avatarfns="data => imgUrl = data[0]" :imageUrlNum="1" :imgUrl="imgUrl ? [imgUrl] : []" :urlSize="1024" />
    <div style="color: red; margin-top:5px">{{imgUrl ? '' : '请上传图片'}}</div>
  </a-modal>
</template>
<script>
import UpdateImg from "@/components/file/updataImg";
import { studyTagsDetail, studyTagsContentSave } from '@/request/api/coursManage'
export default {
  components: { UpdateImg },
  props: {
    title: {
      type: String,
      default: '内容管理'
    },
    isShow: {
      type: Boolean,
      default: false
    },
    tagNo: {
      type: String,
    }
  },
  data() {
    return {
      imgUrl: ''
    }
  },
  watch: {
    tagNo(val, oldval) {
      console.log(val, oldval)
      if (val) this.getDetail()
    }
  },
  methods: {
    async getDetail() {
      const { code, data } = await studyTagsDetail({tagNo: this.tagNo})
      if (code !== 200) return
      const { content } = data
      this.imgUrl = content
    },
    async handleOk() {
      if(!this.imgUrl) return
      const params = {
        tagNo: this.tagNo,
        content: this.imgUrl
      }
      const { code } = await studyTagsContentSave(params)
      if (code !== 200) return
      this.$message.success('操作成功')
      this.$emit('close')
    },
    handleCancel() {
      this.imgUrl = ''
      this.$emit('close')
    }
  }
}
</script>