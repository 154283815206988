<template>
  <div>
    <div v-show="$route.name === 'StudyPlanLabel'">
      <div class="btn-box flex">
        <a-button class="btn" icon="plus" type="primary" @click="modalVisible=true">新建标签</a-button>
      </div>
      <!-- 表格 -->
      <a-table :columns="columns" :data-source="tableData" row-key="tagNo" :pagination="false" :loading="tableLoading">
        <span slot="number" slot-scope="number, record, index">
          <span>{{ index + 1 }}</span>
        </span>
        <span slot="putawayFlag" slot-scope="putawayFlag, recode">
          {{ +putawayFlag === 0 ? '未上架' : '已上架'}}
        </span>
        <span slot="tagType" slot-scope="tagType, recode">
          {{ tagType | getTagType}}
        </span>
        <span slot="operation" slot-scope=" operation, record">
          <a class="margin_right20" @click="onPut(record)">{{ record.putawayFlag ? '下架' : '上架' }}</a>
          <a class="margin_right20" @click="showModal(record)">编辑</a>
          <a class="margin_right20" @click="handleContent(record)">内容管理</a>
        </span>
      </a-table>
      <!-- 添加/编辑 -->
      <a-modal
        title="添加/编辑"
        :visible="modalVisible"
        :confirm-loading="confirmLoading"
        :footer="null"
        :destroy-on-close="true"
        @cancel="onCancel"
      >
        <a-form-model
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="标签名称" ref="tagTitle" prop="tagTitle">
            <a-input v-model="ruleForm.tagTitle" placeholder="请输入标签名称" />
          </a-form-model-item>
          <a-form-model-item label="排序" ref="sort" prop="sort">
            <a-input v-model="ruleForm.sort" placeholder="数字越大，顺序越靠前" />
          </a-form-model-item>
          <a-form-model-item v-if="!ruleForm.tagNo" label="标签类型" ref="tagType" prop="tagType">
            <a-select v-model="ruleForm.tagType" default-value="课程-不分章节" style="width: 160px">
              <a-select-option :value="1">
                课程-不分章节
              </a-select-option>
              <a-select-option :value="2">
                课程-分章节
              </a-select-option>
              <a-select-option :value="3">
                图片
              </a-select-option>
              <a-select-option :value="4">
                跳转圈子
              </a-select-option>
            </a-select>
            <div style="color: #bfbfbf">标签一经建立不可修改</div>
          </a-form-model-item>
          <a-form-model-item v-else label="标签类型" ref="tagType" prop="tagType">
            {{ ruleForm.tagType | getTagType}}
          </a-form-model-item>
          <a-form-model-item label="上架设置" ref="putawayFlag" prop="putawayFlag">
            <a-radio-group v-model="ruleForm.putawayFlag">
              <a-radio :value="0">
                暂不上架
              </a-radio>
              <a-radio :value="1">
                立即上架
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          
          <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" @click="submitForm('ruleForm')">
              确定
            </a-button>
            <a-button style="margin-left: 10px" @click="onCancel">
              取消
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <UploadImage :is-show="showUpdateImgModal" :tag-no="ruleForm.tagNo" :title="ruleForm.modalTitle" @close="() => showUpdateImgModal = false" />
    </div>
    <router-view />
  </div>
</template>
<script>
import { studyTagsList, addStudyTags,editStudyTags, putawayStudyTags } from '@/request/api/coursManage'
import { studyPlanTagsColumns } from '../constant'
import UploadImage from './components/Picture.vue'
const validateSort = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入排序'));
  } else if (isNaN(+value)) {
    callback(new Error('请输入合法数字'));
  } else {
    callback()
  }
}
export default {
  name: 'StudyPlan',
  components: { UploadImage },
  data() {
    return {
      showUpdateImgModal: false,
      courseNo: this.$route.params.courseNo,
      stageNo: this.$route.params.stageNo,
      columns: studyPlanTagsColumns,
      tableLoading: false,
      tableData: [],
      modalVisible: false,
      confirmLoading: false,
      ruleForm: {
        tagTitle: '',
        sort: 0,
        putawayFlag: 1,
        tagType: 1
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      
      rules: {
        tagTitle: [
          { required: true, message: '请输入标签名称', trigger: 'blur' },
        ],
        sort: [
          { required: true, validator: validateSort ,trigger: 'change' },
        ],
        putawayFlag: [{required: true,}],
        tagType: [{required: true,}]
      },
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const params = {
        courseNo: this.courseNo,
        stageNo: this.stageNo
      }
      const { code, data } = await studyTagsList(params)
      if (code !==200) return
      this.tableData = data
    },
    showModal(stage) {
      this.ruleForm = stage,
      this.modalVisible = true
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          +this.ruleForm.tagNo ? this.edit() : this.add()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 添加
    async add() {
      const params = {
        courseNo: this.courseNo,
        stageNo: this.stageNo,
        ...this.ruleForm,
        sort: +this.ruleForm.sort
      }
      const { code } = await addStudyTags(params)
      if (code !== 200) return
      this.$message.success('添加成功')
      this.onCancel()
      this.fetchData()
    },
    // 编辑
    async edit() {
      const params = {
        courseNo: this.courseNo,
        stageNo: this.stageNo,
        ...this.ruleForm,
        sort: +this.ruleForm.sort
      }
      const { code } = await editStudyTags(params)
      if (code !== 200) return
      this.$message.success('修改成功')
      this.onCancel()
      this.fetchData()
    },
    // 取消/关闭
    onCancel() {
      this.ruleForm = {
        tagTitle: '',
        sort: 0,
        putawayFlag: 1,
        tagType: 1
      },
      this.modalVisible = false
    },
    // 上架
    async onPut(recode) {
      const params = {
        tagNo: recode.tagNo,
        putawayFlag: recode.putawayFlag ? 0 : 1
      }
      const {code} = await putawayStudyTags(params)
      if (code !== 200) return
      const text = recode.putawayFlag ? '下架' : '上架'
      this.$message.success(`${text}成功`)
      recode.putawayFlag = recode.putawayFlag ? 0 : 1
    },
    // 内容管理
    handleContent(recode) {
      // 1课程-不分章节，2课程-分章节，3图片，4跳转圈子
      if (+recode.tagType === 3 || +recode.tagType === 4) {
        recode.modalTitle = +recode.tagType === 3 ? '内容管理-图片' : '内容管理-圈子跳转'
        this.showUpdateImgModal = true
        this.ruleForm = recode
      } else {
        const catalogue = +recode.tagType === 1 ? false : true
        const path = `/coursManage/courseList/studyPlan/content/${this.courseNo}/${this.stageNo}/${recode.tagNo}?catalogueFlag=${catalogue}`
        this.$router.push(path)
      }
    }
  },
  filters: {
    getTagType(val) {
      let type = ''
      switch(val) {
        case 1:
          type = '课程-不分章节'
          break;
        case 2: 
          type = '课程-分章节'
          break;
        case 3: 
          type = '图片'
          break;
        case 4: 
          type = '跳转圈子'
          break;
      }
      return type
    }
  }
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
}
.switch {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
</style>